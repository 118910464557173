import * as bootstrap from "bootstrap";

// Popovers
// Note: Disable this if you're not using Bootstrap's Popovers
// const popoverTriggerList = [].slice.call(document.querySelectorAll("[data-bs-toggle=\"popover\"]"))
// popoverTriggerList.map((popoverTriggerEl) => {
//   return new bootstrap.Popover(popoverTriggerEl)
// })

// Tooltips
window.enableTooltip = () => {

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if(isMobile === false) {

        const tooltipTriggerList = [].slice.call(document.querySelectorAll("[data-bs-toggle=\"tooltip\"]"))
        tooltipTriggerList.map((tooltipTriggerEl) => {
            return new bootstrap.Tooltip(tooltipTriggerEl, {
                trigger: 'hover'
            })
        })

    }

}

// Bootstrap
// Note: If you want to make bootstrap globally available, e.g. for using `bootstrap.modal`
window.bootstrap = bootstrap;
