require('./bootstrap');

// Vue
import { createApp, nextTick, ref } from 'vue/dist/vue.esm-bundler.js';
window.createApp = createApp;
window.nextTick = nextTick;
window.ref = ref;

// jQuery
window.$ = window.jQuery = require('jquery');

// Select2
require('select2');
$.fn.select2.defaults.set("theme", "bootstrap4");

// for Template
import "./modules/bootstrap";
import "./modules/theme";
import "./modules/feather";
import "./modules/sidebar";

// Datatable
require('datatables.net')
require('datatables.net-bs4')
require('datatables.net-responsive')

// Chart
import "./modules/chartjs";

// Calendar
import "./modules/fullcalendar";

// Moment
import "./modules/moment";

// Notyf
import "./modules/notyf";

// Sidebar
import "./modules/sidebar";
